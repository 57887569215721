
/*
 * Add some aspect ratio helpers for 16/9
 */
.youtube__player-container {
  position: relative;
  padding: 0;
  padding-top: 56.25%;
  .youtube__player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}

.youtube___list {
  td {
    vertical-align: middle;
  }
  td + td {
    padding-left: 0;
  }
  /* break table layout to get progress bar to expand to the whole row */
  table, tr {
    display: block;
    width: 100%;
  }
  tr {
    position: relative;
  }
  /* redo borders because of table layout change */
  td {
    border: 0;
  }
  tr {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }
  tr + tr {
    border-top: none;
  }
  /* place progress bar across the row */
  .progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background-color: #007bff22;
  }
  /* override tr style */
  tr.youtube___list--active {
    background-color: rgba(43, 174, 35, 0.2) !important;
    background-color: #2bae2333 !important;
  }
  .btn {
    width: 8rem;
    height: 4.5rem;
  }
  .youtube___list--playable {
    .btn {
      color: #dc3545;
    }
    .btn:hover {
      color: #c11425;
    }
  }
}
