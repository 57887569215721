
#temperatureImage {
  width: 35px;
  height: 35px;
}

#toneImage {
  width: 35px;
  height: 35px;
}

#volumeImage {
  width: 35px;
  height: 35px;
}