
/* Allow table to scroll horizontally and not mess with mobile view widths */
@media screen {
  .react-bootstrap-table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden; /* explicity specify so Webkit doesn't add a vertical scrollbar */
  }
}

.react-bootstrap-table table {
  table-layout: auto;
  /* fix scroll-bars being far from table content */
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .react-bootstrap-table2-search-header {
    width: 200px;
  }
}

/* align icons inside table cells */
table tr td svg {
  vertical-align: -25%;
}

/* align icons inside table buttons */
table tr .btn svg {
  vertical-align: -17%;
}

/* ensure table buttons don't put icons and text on multiple lines. tested on IE11 and Safari */
table tr .btn {
  white-space: nowrap;
}
