
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

/* set different sizes for collapsed and expanded sidebar states */
$collapsed-size: 0;
$expanded-size: 300px;

#sidebar {
  /* hide when not logged in */
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
}

.user-is-logged-in #sidebar {
  /* show when logged in */
  display: flex;
  flex-direction: column;
}
.expanded #sidebar {
  width: 100%;
}

.sidebar__full-width {
  position: relative;
  width: 100%;
  height: 100%;
  /* note: flex-basis:auto is extremely important for IE10 and 11 which defaults to flex-basis:0 */
  flex: 1 1 auto; /* fill remaining vertical space (requires height value to be set) */
}

.sidebar__body {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  /* prevent drawer from covering the entire screen */
  max-width: 90vw;
  /* same colour as body, with drop shadow */
  background-color: #f8f9fa;
  box-shadow: 0 0 5px #00000040;
  box-shadow: 0 0 5px rgba(0,0,0,0.25);
  transition: width 0.5s;
}

.sidebar__body .sidebar__inner {
  width: $expanded-size;
  max-width: 90vw;
}
.sidebar__body .sidebar__inner-scroll {
  /* allow element to take up as much or little flex space needed */
  flex: 1 1 auto;
  /* allow scrolling inside this space */
  /* note overflow-x is specifically disabled because in IE
     Bootstrap dropdowns can cause horizontal scrolling due to popperJS
     dropdown positioning upon dropdown creation */
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar__background {
  background-color: #00000000;
  background-color: rgba(0,0,0,0);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  color: white;
  border: none;
  outline: none;
  transition: background-color 0.5s ease, width 0s ease 0.5s;
}

.sidebar__background svg {
  display: none;
  margin: 0.2em;
}
.expanded {
  .sidebar__background {
    width: 100%;
    background-color: #000000AA;
    background-color: rgba(0,0,0,0.67);
    transition: background-color 0.5s ease;
  }
  .sidebar__background svg {
    display: block;
  }
}

.user-is-logged-in {
  .sidebar__body {
    width: $collapsed-size;
  }
  #header,
  #body,
  #footer .footer--floating,
  #footer .footer--non-floating {
    margin-left: $collapsed-size;
  }
}

/* force expanded size when using expanded class name */
.expanded.user-is-logged-in {
  .sidebar__body {
    width: $expanded-size;
  }
}

/* force expanded size on large screens */
@media screen and (min-width: map-get($grid-breakpoints, md)) {
  .user-is-logged-in {
    .sidebar__body {
      width: $expanded-size;
    }
    #header,
    #body,
    #footer .footer--floating,
    #footer .footer--non-floating {
      margin-left: $expanded-size;
    }
    .sidebar__background {
      display: none;
    }
  }
}

.sidebar__logo {
  max-height: 2.375rem;
  max-width: 100%;
}

/* Align Sidebar footer content to left */
#sidebar .footer--non-floating .col-auto {
  margin-left: 0 !important;
  margin-right: auto !important;
}
#sidebar .footer--non-floating .col-auto.col-auto {
  margin-right: 0 !important;
}

/* tree view styles */
.tree__node--ancestor-of-selected > .bp3-tree-node-content > .bp3-tree-node-caret {
  color: #137cbd;
}

/* Remove dropdown arrow on tree-selector components */
.bp3-tree .dropdown-toggle::after {
  border: none;
}
.bp3-tree-node-secondary-label>.must-hover.dropdown {
  display: none;
}
.bp3-tree-node-content:hover>.bp3-tree-node-secondary-label>.must-hover.dropdown {
  display: block;
}

/* Ensure dropdowns inside collapsible containers show above the next tree item */
/* this overrides the translate(0px) originally set, which may have been for old Safari version compatibility */
.bp3-collapse .bp3-collapse-body {
  transform: none !important;
}

/* Indicate that the tree nodes are clickable */
.bp3-tree-node-content {
  cursor: pointer;
}
