
.fitmachine-alarm-card {
  .flow-col-lg {
    flex-basis: 32em;
  }
  .flow-col-md {
    flex-basis: 24em;
  }
  .flow-col-sm {
    flex-basis: 18em;
  }
  .flow-col-xs {
    flex-basis: 12em;
  }
  .flow-col-xxs {
    flex-basis: 5em;
  }
}
