
.home .card h2 {
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0;
  text-align: left;
}

.home .card {
  margin-bottom: 15px;
}

.home .card .react-bootstrap-table-pagination {
  margin-right: 15px;
}

.home .card .card-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
}

.home .echarts-for-react {
  padding: 15px;
}

.home .card .btn {
  margin-bottom: 1rem;
}

.home .flow-col {
  flex-basis: 28em;
  flex-grow: 1;
}
.home .flow-col-2x {
  flex-basis: 56em;
  flex-grow: 2;
}
